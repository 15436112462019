<template>
  <form @submit.prevent="sendFile" enctype="multipart/form-data">
    <div>
      <div class="message-body" v-if="message">{{ message }}</div>
    </div>
    <div class="dropzone">
      <input type="file" class="input-file" ref="file" @change="sendFile" />
      <p v-if="!uploading" class="call-to-action">Drop your file here</p>
      <p v-if="uploading" class="progress-bar">
        <progress class="progress is-primary" :value="progress" max="100">
          {{ progress }}%
        </progress>
        {{ progress }}%
      </p>
    </div>

    <div class="content">
      <ul>
        <li v-for="file in uploadedFiles" :key="file.originalname">
          {{ file.originalname }}
        </li>
      </ul>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "DropZone",
  data() {
    return {
      file: "",
      error: false,
      message: "",
      uploading: false,
      uploadedFiles: [],
      progress: 0,
    };
  },
  methods: {
    async sendFile() {
      const file = this.$refs.file.files[0];
      if (!file) {
        this.message = "No file selected";
        return;
      }

      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      const MAX_SIZE = 10485760;
      const tooLarge = file.size > MAX_SIZE;

      if (allowedTypes.includes(file.type) && !tooLarge) {
        this.file = file;
        this.error = false;
        this.message = "";
      } else {
        this.error = true;
        this.message = tooLarge
          ? `Too large. Max size is ${MAX_SIZE / 1048576}MB`
          : "Only images are allowed";
        return; // Stop if validation fails
      }

      const formData = new FormData();
      formData.append("file", this.file);

      try {
        this.uploading = true;
        const res = await axios.post("/api/images/upload", formData, {
          onUploadProgress: (e) =>
            (this.progress = Math.round((e.loaded * 100) / e.total)),
        });
        this.uploadedFiles.push(res.data.file);
        this.error = false;
      } catch (err) {
        this.message = err.response?.data?.error || "Upload failed.";
        console.error(err); // Log the full error object for debugging
        this.error = true;
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>

<style scoped>
.dropzone {
  min-height: 200px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  outline: 2px dashed #004aad;
  outline-offset: -10px;
  color: #004aad;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.dropzone:hover {
  color: #ffde59;
  background: #004aad;
  outline: 2px dashed #ffde59;
}
.dropzone .call-to-action {
  font-size: 1.2rem;
  text-align: center;
  padding: 75px 0;
  font-weight: 500;
}
.dropzone .progress-bar {
  text-align: center;
  padding: 70px 10px;
}
</style>
