<template>
  <!-- Display disclaimer if the user is not verified -->
  <div v-if="!isVerified" class="not-verified-user-disclaimer">
    <p>You must verify your email to register a corporate account.</p>
  </div>

  <!-- Display the registration form if the user is verified -->
  <div v-if="isVerified" class="form-container">
    <label for="user-form" class="title">
      <i class="fa-solid fa-user-tie fa-lg"></i>
      Corporate Registration
    </label>
    <form @submit.prevent="handleSubmit">
      <label for="name">Company Name</label>
      <input type="text" id="name" v-model="form.name" required /><br /><br />

      <label for="website">Website Address</label>
      <input
        type="url"
        id="website"
        v-model="form.website"
        required
      /><br /><br />

      <label for="sector">Sector</label>
      <input
        type="text"
        id="sector"
        v-model="form.sector"
        required
      /><br /><br />

      <div class="country-container">
        <label for="country">Country</label>
        <input
          type="text"
          id="country"
          v-model="countrySearchQuery"
          @input="filterCountries"
          @keydown="navigateCountryList"
          required
        /><br /><br />
        <ul id="country-list" v-if="filteredCountries.length > 0">
          <li
            v-for="(country, index) in filteredCountries"
            :key="country"
            :class="{ selected: index === selectedCountryIndex }"
            @click="selectCountry(country)"
          >
            {{ country }}
          </li>
        </ul>
      </div>

      <label for="nif">NIF/Tax ID</label>
      <input type="text" id="nif" v-model="form.nif" required /><br /><br />

      <!-- File input for uploading a company logo or document -->
      <label for="logo-file-upload">Logo</label>
      <DropZone
        @file-selected="handleFileSelected"
        label="Upload Company Logo"
        :multiple="false"
        :acceptedFormats="'image/*'"
        id="logo-file-upload"
      />

      <button type="submit" class="submit-button">Register Corporate</button>
    </form>

    <!-- Notification Component -->
    <NotificationCustom
      v-for="(notification, index) in notifications"
      :key="index"
      :type="notification.type"
      :message="notification.message"
      :duration="notification.duration"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import countries from "@/assets/location/countries/countries.json";
import NotificationCustom from "@/components/common/NotificationCustom.vue";
import DropZone from "@/components/common/Dropzone.vue";
import axios from "axios";

export default {
  name: "RegisterCorporate",
  components: { NotificationCustom, DropZone },
  data() {
    return {
      form: {
        name: "",
        website: "",
        sector: "",
        country: "",
        nif: "",
        logo: "",
      },
      selectedFile: null,
      countrySearchQuery: "",
      filteredCountries: [],
      selectedCountryIndex: -1,
      notifications: [],
    };
  },
  computed: {
    ...mapGetters(["isVerified"]),
    isVerified() {
      console.log("Is user verified?", this.$store.getters.isVerified);
      return this.$store.getters.isVerified;
    },
  },
  methods: {
    // Handle form submission
    async handleSubmit() {
      // Update country in form from search query
      this.form.country = this.countrySearchQuery;

      try {
        const response = await axios.post(
          "/api/corporates/register",
          this.form
        );
        console.log("Form submitted:", response.data);

        // Show success notification and emit event
        this.addNotification("Corporate registered successfully!", "success");

        // Ensure the response contains the correct structure
        const corporateId = response.data._id || response.data.id;
        this.$emit("corporate-registered", { id: corporateId });
      } catch (error) {
        console.error("Error submitting form:", error.response?.data || error);
        this.addNotification("Error submitting form.", "error");
      }
    },

    // Filter countries based on search query
    filterCountries() {
      if (this.countrySearchQuery.length > 0) {
        this.filteredCountries = countries.filter((country) =>
          country
            .toLowerCase()
            .startsWith(this.countrySearchQuery.toLowerCase())
        );
        this.selectedCountryIndex = -1; // Reset index when query changes
      } else {
        this.filteredCountries = [];
      }
    },

    // Select a country from the filtered list
    selectCountry(country) {
      this.filteredCountries = [];
      this.countrySearchQuery = country;
      this.selectedCountryIndex = -1;
    },

    // Navigate through the filtered countries list using keyboard
    navigateCountryList(event) {
      if (this.filteredCountries.length > 0) {
        if (event.key === "ArrowDown") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex + 1) % this.filteredCountries.length;
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex - 1 + this.filteredCountries.length) %
            this.filteredCountries.length;
        } else if (event.key === "Enter") {
          if (this.selectedCountryIndex >= 0) {
            event.preventDefault();
            this.selectCountry(
              this.filteredCountries[this.selectedCountryIndex]
            );
          }
        }
      }
    },

    // Add a notification
    addNotification(message, type, duration = 10000) {
      this.notifications.push({ message, type, duration });
      setTimeout(() => {
        this.notifications.shift();
      }, duration);
    },
  },
};
</script>

<style scoped>
.not-verified-user-disclaimer {
  text-align: center;
  padding: 20px;
  background-color: #ffefc1;
  border: 1px solid #ffcc00;
  border-radius: 8px;
  color: #cc6600;
  margin: 20px auto;
  max-width: 500px;
}
.form-container {
  background-color: white;
  padding: 20px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px auto 26px auto;
  text-align: center;
  color: #004aad;
}

h2 {
  color: #004aad;
  text-align: center;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #004aad;
}

input[type="text"],
input[type="url"],
input[type="file"],
textarea {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

input[type="text"]:focus,
input[type="url"]:focus,
input[type="file"]:focus,
textarea:focus {
  border-color: #004aad;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 74, 173, 0.5);
}
.country-container {
  display: block;
  position: relative;
}
#country-list {
  position: absolute;
  top: calc(100% - 24px);
  left: 0;
  width: 100%;
  z-index: 999;
  max-height: 333px;
  overflow-y: auto;
  background-color: white;
  border-top: none;
  border-radius: 8px;
  border: 1px solid #004aad;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style-type: none;
}
#country-list li {
  padding: 0.67rem 1rem;
  background: white;
}

#country-list li:hover,
#country-list .selected {
  background-color: #004aad;
  color: #ffde59;
  cursor: pointer;
}
.submit-button {
  display: block;
  margin: 25px auto 0 auto;
  padding: 10px 16px;
  background-color: #004aad;
  color: #ffde59;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.submit-button:hover {
  background-color: #ffde59;
  color: #004aad;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

li {
  padding: 10px;
  cursor: pointer;
}

li.selected {
  background-color: #ddd;
}
.input-file-container {
  width: 100%;
  height: 167px;
  display: flex;
  cursor: pointer;
  margin: 0 0 20px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed #004aad;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-container :where(i, p) {
  color: #004aad;
}

.input-file-container > i {
  font-size: 50px;
}
.input-file-container > p {
  margin-top: 15px;
  font-size: 16px;
}
::selection {
  color: #fff;
  background: #004aad;
}
section .row {
  margin-bottom: 10px;
  background: #e9f0ff;
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section .row i {
  color: #004aad;
  font-size: 30px;
}
section .details span {
  font-size: 14px;
}
.progress-area .row .content {
  width: 100%;
  margin-left: 15px;
}
.progress-area .details {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: space-between;
}
.progress-area .content .progress-bar {
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 30px;
}
.content .progress-bar .progress {
  height: 100%;
  width: 0%;
  background: #004aad;
  border-radius: inherit;
}
.uploaded-area {
  max-height: 232px;
  overflow-y: scroll;
}
.uploaded-area.onprogress {
  max-height: 150px;
}
.uploaded-area::-webkit-scrollbar {
  width: 0px;
}
.uploaded-area .row .content {
  display: flex;
  align-items: center;
}
.uploaded-area .row .details {
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}
.uploaded-area .row .details .size {
  color: #404040;
  font-size: 11px;
}
.uploaded-area i.fa-check {
  font-size: 16px;
}
</style>
