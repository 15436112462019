<template>
  <div class="remind-email-verification">
    <p>
      Please verify your email. Check your inbox or
      <button
        class="resend-button"
        @click="resendVerification"
        :disabled="loading"
      >
        {{ loading ? "Resending..." : "Resend verification" }}
      </button>
    </p>
    <p v-if="message" :class="{ error: isError }">{{ message }}</p>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "RemindEmailVerification",
  data() {
    return {
      loading: false, // Button loading state
      message: "", // Success or error message
      isError: false, // Whether the message is an error
    };
  },
  computed: {
    ...mapState(["user"]), // Map Vuex user state (ensure you have user in Vuex)
  },
  methods: {
    async resendVerification() {
      this.loading = true; // Set loading state

      try {
        const response = await axios.post("/api/users/resend-verification", {
          userId: this.user._id,
        });

        this.message = response.data.message; // Set success message
        this.isError = false; // Not an error
      } catch (error) {
        this.message =
          error.response?.data?.error || "An error occurred. Please try again.";
        this.isError = true; // Set error flag
      } finally {
        this.loading = false; // Reset loading state
      }
    },
  },
};
</script>

<style scoped>
.remind-email-verification {
  background-color: #ffde59;
  color: black;
  padding: 10px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
}
.remind-email-verification:hover {
  background-color: #f7b500;
}
.resend-button {
  font-weight: 500;
  color: #004aad;
}
.resend-button:hover {
  text-decoration: underline;
}
.error {
  color: #e60000;
  font-weight: 500;
}
</style>
