<template>
  <div class="form-container">
    <label for="user-form" class="title">
      <i class="fa-solid fa-user fa-lg"></i>
      User Registration
    </label>
    <form id="user-form" @submit.prevent="handleSubmit">
      <label for="name">Name and surname</label>
      <input type="text" id="name" v-model="form.name" required /><br /><br />

      <label for="jobPosition">Job Position</label>
      <input
        type="text"
        id="jobPosition"
        v-model="form.jobPosition"
        required
      /><br /><br />

      <label for="email">E-mail</label>
      <input
        type="email"
        id="email"
        v-model="form.email"
        required
      /><br /><br />

      <label for="telephone">Telephone</label>
      <input
        type="tel"
        ref="telephone"
        name="telephone"
        id="telephone"
        v-model="form.telephone"
        @input="restrictToDigits"
      />
      <br /><br />

      <div class="password-div">
        <label for="password">Password</label>
        <div class="input-and-button">
          <input
            :type="passwordFieldType"
            autocomplete="off"
            class="password"
            name="password"
            id="password"
            v-model="form.password"
            required
          />
          <button type="button" class="toggle-password" @click="togglePassword">
            <i :class="passwordIcon"></i>
          </button>
        </div>
      </div>
      <span
        v-if="!isPasswordComplex() && form.password.length > 0"
        class="error-message"
      >
        {{ passwordErrorMessage }}
      </span>
      <br /><br />

      <button type="submit" class="submit-button">Register User</button>
    </form>

    <!-- Include NotificationCustom component for notifications -->
    <NotificationCustom
      v-if="notificationVisible"
      :type="notificationType"
      :message="notificationMessage"
      @hide="notificationVisible = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import "vue-toastification/dist/index.css";
import NotificationCustom from "@/components/common/NotificationCustom.vue";

export default {
  name: "RegisterUser",
  components: {
    NotificationCustom,
  },
  data() {
    return {
      form: {
        name: "",
        jobPosition: "",
        email: "",
        telephoneCountryPrefix: "",
        telephone: "",
        password: "",
      },
      telephoneErrorMessage: "",
      passwordErrorMessage: "",
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
      notificationVisible: false,
      notificationType: "",
      notificationMessage: "",
    };
  },
  methods: {
    async handleSubmit() {
      try {
        if (!this.isPasswordComplex()) {
          this.showNotification("error", this.passwordErrorMessage);
          return;
        }
        if (!this.isPhoneValid()) {
          this.showNotification("error", this.telephoneErrorMessage);
          return;
        }
        const response = await axios.post("/api/users/register", {
          name: this.form.name,
          jobPosition: this.form.jobPosition,
          email: this.form.email,
          password: this.form.password,
          telephone: this.form.telephone,
          telephoneCountryPrefix: this.form.telephoneCountryPrefix || "1", // Default to "1" (USA/Canada)
        });

        // Show success notification and inform user about email verification
        this.showNotification("success", response.data.message);

        // Clear the form after successful registration
        this.form.name = "";
        this.form.jobPosition = "";
        this.form.email = "";
        this.form.telephone = "";
        this.form.password = "";
        this.form.telephoneCountryPrefix = "";

        // Redirect to a confirmation page or the user's account
        this.$router.push({ name: "your-account" });
      } catch (error) {
        if (error.response) {
          this.showNotification("error", error.response.data.error);
        } else {
          this.showNotification(
            "error",
            "An error occurred while registering the user."
          );
        }
      }
    },
    restrictToDigits(event) {
      const input = event.target.value;
      this.form.telephone = input.replace(/\D/g, ""); // Restrict to digits
    },
    isPhoneValid() {
      const phoneRegex = /^[0-9]{9,15}$/;
      if (!phoneRegex.test(this.form.telephone)) {
        this.telephoneErrorMessage =
          "Phone number must be between 9 and 15 digits.";
        return false;
      } else {
        this.telephoneErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },
    initializePhoneInput() {
      this.$nextTick(() => {
        if (this.iti) {
          this.iti.destroy(); // Destroy any existing instance
        }

        const iti = intlTelInput(this.$refs.telephone, {
          initialCountry: this.form.telephoneCountryPrefix || "auto",
          geoIpLookup: (callback) => {
            fetch("https://ipinfo.io/json")
              .then((resp) => resp.json())
              .then((resp) => {
                const countryCode = resp && resp.country ? resp.country : "us";
                callback(countryCode);
              })
              .catch(() => callback("us"));
          },
          autoHideDialCode: false,
          nationalMode: false,
          utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });

        iti.promise.then(() => {
          // Set the country prefix based on selected country
          this.form.telephoneCountryPrefix =
            iti.getSelectedCountryData().dialCode || "1";
          console.log(
            "Country prefix set to:",
            this.form.telephoneCountryPrefix
          );

          this.$refs.telephone.addEventListener("countrychange", () => {
            this.form.telephoneCountryPrefix =
              iti.getSelectedCountryData().dialCode || "1";
            console.log(
              "Country prefix changed to:",
              this.form.telephoneCountryPrefix
            );
          });
        });

        this.iti = iti; // Save reference to destroy later if needed
      });
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },
    isPasswordComplex() {
      if (!this.form.password) {
        return true;
      }
      const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;
      const password = this.form.password;
      if (password.length < 10) {
        this.passwordErrorMessage =
          "Password must contain at least 10 characters";
        return false;
      } else if (!symbolRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one special symbol";
        return false;
      } else if (!uppercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one uppercase letter";
        return false;
      } else if (!lowercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one lowercase letter";
        return false;
      } else if (!numberRegex.test(password)) {
        this.passwordErrorMessage = "Password must contain at least one number";
        return false;
      } else {
        this.passwordErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },
    showNotification(type, message) {
      this.notificationType = type;
      this.notificationMessage = message;
      this.notificationVisible = true;

      setTimeout(() => {
        this.notificationVisible = false;
      }, 10000);
    },
  },
  mounted() {
    this.initializePhoneInput();
  },
};
</script>

<style scoped>
.form-container {
  background-color: white;
  padding: 20px;
  margin: 10px auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px auto 26px auto;
  text-align: center;
  color: #004aad;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #004aad;
}

input[type="text"],
input[type="tel"],
input[type="email"],
select {
  border-radius: 8px;
  width: 100% !important;
  margin: auto;
  padding: 0.67rem 1rem;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
  border-color: #004aad;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 74, 173, 0.5);
}

#telephone {
  width: 460px !important;
}
@media (max-width: 380px) {
  #telephone {
    width: 335px !important;
  }
}
.password-div {
  display: flex;
  flex-direction: column;
}
.input-and-button {
  display: flex;
  flex-direction: row;
}
.toggle-password {
  min-width: 50px;
  padding: 0.67rem;
  background-color: #004aad;
  border-radius: 0 8px 8px 0;
  color: #ffde59;
  border: 1px solid #004aad;
  border-left: none;
  flex: 1;
  margin: 0;
}
.toggle-password:hover {
  background-color: #ffde59;
  color: #004aad;
}
.password {
  border-radius: 8px 0 0 8px !important;
  width: calc(100% - 50px);
  margin: auto;
  padding: 0.67rem 1rem;
  border: 1px solid #ccc;
}
.password:focus {
  border: 1px solid #004aad;
  border-radius: 8px 0 0 8px !important;
  width: calc(100% - 50px);
  margin: auto;
  padding: 0.67rem 1rem;
  border: 1px solid #ccc;
}
.password:focus {
  border-color: #004aad;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 74, 173, 0.5);
}

.submit-button {
  display: block;
  margin: 0 auto;
  padding: 10px 16px;
  background-color: #004aad;
  color: #ffde59;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.submit-button:hover {
  background-color: #ffde59;
  color: #004aad;
}
.error-message {
  display: block;
  color: #ff0000;
  margin: 5px 0 10px 0;
  width: 400px;
  text-align: center;
}
.admin-disclaimer {
  display: block;
  text-align: center;
  font-style: italic;
  color: #004aad;
}
</style>
