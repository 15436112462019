<template>
  <div class="aside-section">
    <!-- h2 Greeting -->
    <h2>
      Welcome back,
      <span class="username">{{ username }}</span>
      !
    </h2>
    <!-- Greeting Separator -->
    <div class="separator-greeting"></div>
    <!-- Nav Buttons -->
    <ul class="nav-buttons">
      <li v-for="button in buttons" :key="button.section">
        <div
          @click="handleButtonClick(button)"
          :class="[
            'nav-button',
            {
              'current-button': currentSection === button.section,
              'disabled-button': button.requiresCorporate && !corporateId,
            },
          ]"
          :data-text="
            button.requiresCorporate && !corporateId
              ? 'You need to register a corporate first'
              : ''
          "
        >
          <i :class="button.icon"></i>
          {{ button.text }}
        </div>
        <div v-if="button.separator" class="separator-nav-buttons"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    currentSection: String,
  },
  data() {
    return {
      buttons: [
        {
          section: "UserDetails",
          text: "User Details",
          icon: "fa-solid fa-user",
          separator: false,
        },
        {
          section: "CorporateDetails",
          text: "Corporate Details",
          icon: "fa-solid fa-user-tie",
          separator: false,
          requiresCorporate: true, // Requires corporateId to be accessible
        },
        {
          section: "MessageCenter",
          text: "Message Center",
          icon: "fa-solid fa-mailbox-flag-up",
          separator: true,
        },
        {
          section: "MyFavourites",
          text: "My Favourites",
          icon: "fa-solid fa-heart",
          separator: false,
        },
        {
          section: "SavedSearches",
          text: "Saved Searches",
          icon: "fa-solid fa-user-magnifying-glass",
          separator: true,
        },
        {
          section: "SpacesManagement",
          text: "Spaces Management",
          icon: "fa-solid fa-shop-lock",
          separator: false,
          requiresCorporate: true, // Requires corporateId to be accessible
        },
        {
          section: "ServicesManagement",
          text: "Services Management",
          icon: "fa-solid fa-gears",
          separator: false,
          requiresCorporate: true, // Requires corporateId to be accessible
        },
        {
          section: "AdsManagement",
          text: "Ads Management",
          icon: "fa-solid fa-ad",
          separator: false,
          requiresCorporate: true, // Requires corporateId to be accessible
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    username() {
      return this.user ? this.user.name : "Guest";
    },
    corporateId() {
      return this.user?.corporateId || null;
    },
  },
  methods: {
    handleButtonClick(button) {
      if (!button.requiresCorporate || this.corporateId) {
        this.$emit("section-change", button.section);
      }
    },
  },
};
</script>

<style scoped>
.aside-section {
  max-width: 300px;
  margin-right: 20px;
}

.aside-section h2 {
  font-size: 1.15rem;
  font-weight: 450;
  margin: 25px 0;
}

.username {
  color: #004aad;
}

.separator-greeting {
  background-color: #004aad;
  height: 2.5px;
  width: 100%;
  border-radius: 2.5px;
}

.nav-buttons {
  width: max-content;
  padding-top: 17px;
}

.nav-button {
  color: #004aad;
  font-size: 1.15rem;
  font-weight: 500;
  margin: 8px 0;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.nav-button:hover,
.current-button {
  background-color: #004aad;
  color: #ffde59;
}

.nav-button i {
  margin-right: 4px;
}

.separator-nav-buttons {
  margin: 10px 0;
  border-top: 1px solid black;
  width: 100%;
}

.disabled-button {
  position: relative;
  background-color: #a9a9a9;
  color: #004aad;
}
.disabled-button:hover {
  cursor: not-allowed;
  background-color: #a9a9a9;
  color: #004aad;
}
.disabled-button:after {
  top: 50.25px;
  left: 50%;
  transform: translateX(-50%);
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  opacity: 0;
  background-color: #004aad;
  z-index: 2;
  pointer-events: none;
  color: #ffde59;
  font-weight: 400;
}
.disabled-button:hover::after {
  opacity: 1;
}
</style>
