<template>
  <Header />
  <RegisterCorporate />
  <Footer />
</template>
<script>
import Header from "@/components/common/Header.vue";
import RegisterCorporate from "@/components/SignIn/RegisterCorporate.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "CorporateRegistry",
  components: {
    Header,
    RegisterCorporate,
    Footer,
  },
};
</script>
<style scoped></style>
