import axios from "axios";
import { createStore } from "vuex";

// Function to retrieve the stored user from localStorage
function getStoredUser() {
  try {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error("Error parsing stored user:", error);
    return null;
  }
}

// Retrieve stored user and token from localStorage
const storedUser = getStoredUser();
const storedToken = localStorage.getItem("token") || null;

// Vuex store definition
const store = createStore({
  state: {
    isAuthenticated: !!storedUser,
    user: storedUser,
    token: storedToken,
    corporate: null, // Add a corporate state to store corporate details
  },
  mutations: {
    setUser(state, { user, token }) {
      state.user = user;
      state.isAuthenticated = true;
      state.token = token;

      // Store user and token in localStorage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);

      // Set axios default header for authorization
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    updateUser(state, user) {
      state.user = user;

      // Update user data in localStorage
      localStorage.setItem("user", JSON.stringify(user));
    },
    logout(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;

      // Remove user and token from localStorage
      localStorage.removeItem("user");
      localStorage.removeItem("token");

      // Remove the authorization header from axios
      delete axios.defaults.headers.common["Authorization"];
    },
    setCorporate(state, corporate) {
      state.corporate = corporate;
    },
  },
  actions: {
    // Action to handle user login
    async login({ commit }, { email, password }) {
      try {
        // Make the login request to the backend
        const response = await axios.post("/api/users/login", {
          email,
          password,
        });

        // Commit user and token to state
        commit("setUser", {
          user: response.data.user,
          token: response.data.token,
        });

        // Set authorization header after login succeeds
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
      } catch (error) {
        // Handle 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          throw new Error("Invalid email or password.");
        } else {
          console.error("Error logging in:", error);
          throw new Error("An error occurred during login.");
        }
      }
    },

    // Action to handle user registration
    async register({ commit }, { name, email, password }) {
      try {
        const response = await axios.post("/api/users/register", {
          name,
          email,
          password,
        });

        commit("setUser", {
          user: response.data.user,
          token: response.data.token,
        });

        // Only set the authorization header here after registration succeeds
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
      } catch (error) {
        if (error.response && error.response.status === 409) {
          throw new Error("This email address is already in use.");
        } else {
          console.error("Error registering user:", error);
          throw new Error("An error occurred during registration.");
        }
      }
    },

    // Action to handle user logout
    logout({ commit }) {
      commit("logout");
    },

    // Action to fetch the user data from the API
    async fetchUser({ commit }, userId) {
      try {
        const response = await axios.get(`/api/users/${userId}`);
        commit("updateUser", response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    // Action to fetch corporate details based on the user's corporate ID
    async fetchCorporateDetails({ commit, getters }) {
      const corporateId = getters.corporateId;
      if (!corporateId) {
        throw new Error("Corporate ID not available.");
      }

      try {
        const response = await axios.get(`/api/corporates/${corporateId}`);
        commit("setCorporate", response.data);
        return response.data;
      } catch (error) {
        console.error("Error fetching corporate details:", error);
        throw error; // Re-throwing to handle it in the component if necessary
      }
    },

    // Action to update corporate details
    async updateCorporateDetailsAction({ commit, getters }, corporateData) {
      const corporateId = getters.corporateId;
      if (!corporateId) {
        throw new Error("Corporate ID not available.");
      }

      try {
        const response = await axios.put(
          `/api/corporates/${corporateId}`,
          corporateData
        );
        commit("setCorporate", response.data);
      } catch (error) {
        console.error("Error updating corporate details:", error);
        throw error;
      }
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    isVerified: (state) => state.user?.isVerified || false,
    corporateId: (state) => state.user?.corporateId || null,
    corporate: (state) => state.corporate,
  },
});

export default store;
