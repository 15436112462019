import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import axios from "axios";
import NotFoundPage from "@/views/NotFoundPage.vue";
import HomePage from "@/views/HomePage.vue";
import LogInPage from "@/views/LogInPage.vue";
import UserRegistry from "@/views/UserRegistry.vue";
import CorporateRegistry from "@/views/CorporateRegistry.vue";
import MapResultsPage from "@/views/MapResultsPage.vue";
import RegisterSpaceOrServiceView from "@/views/RegisterSpaceOrService.vue";
import RegisterSpace from "@/views/RegisterSpace.vue";
import YourAccountView from "@/views/YourAccount.vue";
import SpaceDetailsView from "@/views/SpaceDetailsView.vue";
import UserVerificationView from "@/views/UserVerification.vue";
import Dropzone from "@/components/common/Dropzone.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      title: "Biglo | The Place to Find Your Space",
    },
  },
  {
    path: "/log-in",
    name: "log-in",
    component: LogInPage,
    meta: {
      title: "Log In | Biglo",
    },
  },
  {
    path: "/user-registry",
    name: "user-registry",
    component: UserRegistry,
    meta: {
      title: "User Registry | Biglo",
    },
  },
  {
    path: "/corporate-registry",
    name: "corporate-registry",
    component: CorporateRegistry,
    meta: {
      title: "Corporate Registry | Biglo",
    },
  },
  {
    path: "/map-search",
    name: "map-search",
    component: MapResultsPage,
    meta: {
      title: "Map Search | Biglo",
    },
  },
  {
    path: "/register-space-or-service",
    name: "register-space-or-service",
    component: RegisterSpaceOrServiceView,
    meta: {
      title: "Register Space or Service | Biglo",
      requiresAuth: true,
    },
  },
  {
    path: "/register-space",
    name: "register-space",
    component: RegisterSpace,
    meta: {
      title: "Register your Space | Biglo",
      requiresAuth: true,
    },
  },
  {
    path: "/your-account",
    name: "your-account",
    component: YourAccountView,
    meta: {
      title: "Your Account | Biglo",
      requiresAuth: true,
    },
  },
  {
    path: "/spaces/:spaceId",
    name: "SpaceDetails",
    component: SpaceDetailsView,
    meta: {
      title: "Space Details | Biglo",
    },
  },
  {
    path: "/verify-email",
    name: "email-verification",
    component: UserVerificationView,
    meta: {
      title: "User Verification | Biglo",
    },
  },
  {
    path: "/upload-file",
    name: "email-verification",
    component: Dropzone,
    meta: {
      title: "User Verification | Biglo",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found-page",
    component: NotFoundPage,
    metaInfo: {
      title: "404: Page not found | Biglo",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Axios global configuration
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL; // Set your backend URL from environment variables

// Axios request interceptor to add authorization token
axios.interceptors.request.use(
  (config) => {
    const token = store.state.token; // Assuming you store token in Vuex store
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor for error handling
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Handle unauthorized access
      router.push("/log-in");
    }
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters.isAuthenticated
  ) {
    next({
      path: "/log-in",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
