<template>
  <header class="header">
    <div class="header-content">
      <router-link to="/" class="logo">
        <img
          class="logoimg"
          src="@/assets/images/biglo_logo.png"
          alt="Logo image of Biglo"
        />
        <h1 id="logo_name">Biglo</h1>
      </router-link>

      <input type="checkbox" id="check" class="hidden-checkbox" />
      <label for="check" class="checkbtn">
        <i class="fas fa-bars"></i>
      </label>
      <ul class="nav-links">
        <li class="nav-item">
          <router-link
            v-if="!isAuthenticated"
            to="/user-registry"
            class="register"
          >
            <i class="fa-solid fa-user-plus"></i>
            Sign Up
          </router-link>
          <router-link
            v-if="isAuthenticated && !hasCorporate"
            to="/corporate-registry"
            class="register"
          >
            <i class="fa-solid fa-thumbtack"></i>
            Register Corporate
          </router-link>
          <router-link
            v-if="isAuthenticated && hasCorporate"
            to="/register-space-or-service"
            class="register"
          >
            <i class="fa-solid fa-thumbtack"></i>
            Register space/service!
          </router-link>
        </li>
        <li class="nav-item">
          <router-link v-if="!isAuthenticated" to="/log-in" class="sign_in">
            <i class="fa-solid fa-user"></i>
            Log In
          </router-link>
          <router-link
            v-else-if="!isOnYourAccount"
            to="/your-account"
            class="your_account"
          >
            <i class="fa-solid fa-circle-user"></i>
            Your Account
          </router-link>
          <button v-else @click="handleLogout" class="logout">
            <i class="fa-solid fa-arrow-right-from-bracket"></i>
            Log Out
          </button>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CommonHeader",
  computed: {
    ...mapGetters(["isAuthenticated", "corporateId"]),
    isOnYourAccount() {
      return this.$route.path === "/your-account";
    },
    hasCorporate() {
      return !!this.corporateId;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout();
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
header {
  max-width: 90vw;
  margin: 0 auto;
  transition: all 0.5s ease-out;
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  padding: 16px 0;
}
.logo {
  display: flex;
  align-items: center;
}
.logoimg {
  width: 40px;
  height: 40px;
}
#logo_name {
  padding-left: 8px;
  color: #004aad;
  font-size: 2.25rem;
  font-weight: bold;
  margin: 0 auto;
}
.hidden-checkbox {
  display: none;
}
.checkbtn {
  color: #004aad;
  cursor: pointer;
  display: none;
  font-size: 24px;
}
.fa-solid {
  margin-right: 0.33rem;
}

@media (max-width: 768px) {
  .checkbtn {
    display: block;
  }
}
.nav-links {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.nav-item {
  margin-left: 1.25rem;
}
.register,
.sign_in,
.your_account,
.logout {
  display: flex;
  align-items: center;
  color: black;
  border: none;
  border-radius: 9999px;
  padding: 0.625rem 1.25rem;
  height: 2.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.register:hover,
.sign_in:hover,
.your_account:hover,
.logout:hover {
  color: #ffde59;
  background-color: #004aad;
}
@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 4.5rem;
    right: -100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background-color: #ffde59;
    transition: right 0.5s ease-out;
  }
  #check:checked ~ .nav-links {
    right: 0;
  }
  .nav-item {
    margin-top: 2rem;
  }
  .register,
  .sign_in,
  .your_account,
  .logout {
    color: #004aad;
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    margin-top: 1rem;
  }
}
</style>
